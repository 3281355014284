<template>
  <main>
    <v-breadcrumbs
      :items="breadcrumbs"
      divider="-"
    ></v-breadcrumbs>

    <v-progress-circular
      :size="80"
      :width="7"
      color="primary"
      indeterminate
      class="mx-auto d-flex mt-15"
      v-if="loading"
    ></v-progress-circular>

    <div v-else>
      <div class="project-data">
        <v-img
          :aspect-ratio="4 / 3"
          :src="project.logo || 'https://cdn-2.galxe.com/galaxy/images/aboardexchange/1668740795904521013.png?optimizer=image&width=400&quality=100'"
          lazy-src="https://cdn-2.galxe.com/galaxy/images/aboardexchange/1668740795904521013.png?optimizer=image&width=400&quality=100"
        ></v-img>
  
        <div class="project-content">
          <div class="project-name">
            <span>{{ project.name }}</span>
            <check-icon></check-icon>
          </div>
          <div class="project-desc">{{ project.description }}</div>
          <div class="project-options">
            <v-tooltip bottom v-for="(option, index) of options" :key="index">
              <template v-slot:activator="{ on, attrs }">
                <div class="option" v-bind="attrs" v-on="on">
                  <v-icon x-small>{{ option.icon }}</v-icon>
                  {{ option.value }}
                </div>
              </template>
              <span>{{ option.tooltip }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
  
      <v-divider></v-divider>
  
      <h4 class="tag-name">#All Quests</h4>
  
      <div class="quests">
        <v-card
          v-for="(quest, index) of offers"
          :key="index"
          class="quest"
        >
          <div class="quest-content">
            <div class="quest-name">{{ quest.name }}</div>
            <div class="quest-desc">{{ quest.description }}</div>
          </div>
  
          <div class="quest-action d-flex align-center">
            <v-btn color="primary" dense small @click="promote(quest)" v-if="canPromote(quest)">Promote</v-btn>
            <v-btn color="secondary" :ripple="false" dense small v-else>Coming Soon</v-btn>
            <v-spacer></v-spacer>
            <v-chip outlined dense label small>{{getOfferPayout(quest)}} {{quest.token}}</v-chip>
          </div>
        </v-card>
      </div>
    </div>
  </main>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { mdiFormatListBulletedType, mdiAccountOutline, mdiLightningBoltOutline } from '@mdi/js'
import CheckIcon from '@/assets/images/svg/check.svg'

export default {
  components: {
    CheckIcon
  },
  data: () => ({
    offers: [],
    project: {},
    loading: false,
    icons: {
      mdiFormatListBulletedType,
      mdiAccountOutline,
      mdiLightningBoltOutline
    }
  }),
  computed: {
    ...mapGetters('web3auth', ['userdata']),
    breadcrumbs() {
      return [
        {
          text: 'Marketplace',
          to: '/',
        },
        {
          text: `Project Page`,
          disabled: true
        },
      ]
    },
    options() {
      return [
        {
          icon: this.icons.mdiFormatListBulletedType,
          value: this.offers.length,
          tooltip: 'Quests'
        },
        {
          icon: this.icons.mdiAccountOutline,
          value: this.project.wallets || 0,
          tooltip: 'Users'
        },
        {
          icon: this.icons.mdiLightningBoltOutline,
          value: this.project.commissions || 0,
          tooltip: 'Actions'
        },
      ]
    }
  },
  created() {
    this.getOffers()
  },
  methods: {
    getOffers() {
      if (this.loading) return;

      this.loading = true;

      this.$http
        .get(`/publisher/offers/${this.$route.params.projectId}`)
        .then(({ data }) => {
          this.project = data.project;
          this.offers = data.offers.sort((a, b) => this.canPromote(b) - this.canPromote(a));
        })
        .catch(err => {
          console.log(err);

          this.$router.push({ name: 'home' })
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    promote(item) {
      if (!this.userdata._id) {
        return this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
          {
            type: 'warning',
            message: 'Connect your wallet to use the app',
          },
          { root: true },
        )
      }
  
      navigator.clipboard.writeText(`${item.link}?aff=${this.userdata.wallet}`)

      this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
        {
          type: 'success',
          message: 'Successfully copied to clipboard!',
        },
        { root: true },
      )
    },
    getOfferPayout(offer) {
      // NOTE: 10% promote2earn commission
      return (offer.payout - (offer.payout / 100) * 10).toFixed(2)
    },
    canPromote(offer) {
      return offer && offer.status === 'onchain' && (offer.web3data?.budget || 0) > 1;
    },
  },
}
</script>

<style lang="scss" scoped>
.project-data {
  display: flex;
  margin-bottom: 40px;

  .v-image {
    width: 100%;
    max-width: 300px;
    margin-right: 40px;
    border-radius: 20px;
  }

  .project-desc {
    margin-bottom: 20px;
  }

  .project-name {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
      font-size: 33px;
      margin-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      line-clamp: 2;
      text-decoration: none;
      font-weight: 700;
      text-align: left;
      color: #fff;
    }
  }

  .project-options {
    display: flex;

    .option {
      border: 1px solid var(--v-secondary-base);
      margin-right: 6px;
      padding: 2px 8px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: var(--v-secondary-base);

      .v-icon {
        margin-right: 4px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    & {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .v-image {
      margin-bottom: 20px;
    }

    .project-name, .project-options {
      justify-content: center;
    }
  }
}

.tag-name {
  color: var(--v-primary-base);
  font-size: 18px;
  margin: 30px 20px;
}

.quests {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 320px));
  gap: 20px;
  width: 100%;
  padding-bottom: 15px;

  .quest {
    max-width: 330px;
    height: 230px;
    width: 100%;
    position: relative;
    background-size: 220%;
    box-shadow: 2px 4px 8px hsla(249,100%,74%,0.1);
    touch-action: manipulation;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid hsla(249,100%,74%,0.3);
    padding: 15px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out 0s;
  }

  .quest-name {
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-height: 54px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
  }

  .quest-desc {
    font-size: 14px;
    color: var(--v-secondary-base);
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
  }
}
</style>
